import { apiSlice } from './apiSlice';
const USERS_URL = '/product';

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    getProducts: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/getproducts`,
        method: 'POST',
        body: data,
      }),
    }),
    



  }),
});

export const {
  useGetProductsMutation,
 
} = userApiSlice;
