import React, { useState, useEffect, useLayoutEffect , useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Star } from 'lucide-react';
import { useGetReviewableItemsMutation, 
    useGetReviewHistoryMutation, 
    useUpdateReviewMutation,
    useCreateReviewMutation,
    useDeleteReviewMutation
} from '../slices/reviewApiSlice';
import { FaArrowLeft } from 'react-icons/fa';
import { Form, Button, Container, ListGroup, Modal, Form as BootstrapForm, Card, Tabs, Tab, ListGroupItem, Badge, Row, Col  } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import { StarIcon as SolidStar } from '@heroicons/react/solid';
import { StarIcon as OutlineStar } from '@heroicons/react/outline';
import { ProductContext } from '../context/ProductContext';
import { Calendar, Package, Truck, Edit, Trash2 } from "lucide-react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
//import { BsCalendar as Calendar, BsBoxSeam as Package, BsTruck as Truck, BsPencil, BsTrash} from "react-icons/bs";
//import { MdCalendarToday as Calendar, MdLocalShipping as Package, MdEdit as Truck, MdDelete } from "react-icons/md";



import "../styles/reviewPage.css"; 

const ReviewItems = () => {
  const _id = useSelector((state) => state.auth.userInfo._id);
  const userName = useSelector((state) => state.auth.userInfo.name);
  const [activeTab, setActiveTab] = useState('orders');
  
  const [orderItemsPreImage, setOrderItemsPreImage] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [reviewHistoryPreImage, setReviewHistoryPreImage] = useState([]);
  const [reviewHistory, setReviewHistory] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const [error, setError] = useState('');
  const headerRef = useRef(null);
  const detailsRef = useRef(null);
  const [isValidName, setIsValidName] = useState(false);
  const [isBlurName, setIsBlurName] = useState(false);
  const [isValidNameEdit, setIsValidNameEdit] = useState(false);
  const [isBlurNameEdit, setIsBlurNameEdit] = useState(false);

  const [isValidReview, setIsValidReview] = useState(false);
  const [isBlurReview, setIsBlurReview] = useState(false);
  const [isValidReviewEdit, setIsValidReviewEdit] = useState(true);
  const [isBlurReviewEdit, setIsBlurReviewEdit] = useState(false);
  
  const [name, setName] = useState("");
  const [remainingHeight, setRemainingHeight] = useState(0);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const context = useContext(ProductContext);
  const { products = [], loading = false, errorProducts = null } = context || {};

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [editReviewOriginal, setEditReviewOriginal] = useState('');
  const [editRatingOriginal, setEditRatingOriginal] = useState('');
  const [editDisplayNameOriginal, setEditDisplayNameOriginal] = useState('');
  const [selectedReview, setSelectedReview] = useState(null);
  const [nameEdit, setNameEdit] = useState('');
  const [ratingEdit, setRatingEdit] = useState('');
  const [reviewEdit, setReviewEdit] = useState('');

  let userID = _id;

  const [currentPage, setCurrentPage] = useState(1); // New state for current page
  const itemsPerPage = 5; // Items per page



    // Pagination logic
    const totalItems = orderItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const currentItems = orderItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const [currentPageReview, setCurrentPageReview] = useState(1); // New state for current page
    const itemsPerPageReview = 5; // Items per page

        // Pagination logic
        const totalReviewItems = reviewHistory.length;
        const totalReviewPages = Math.ceil(totalReviewItems / itemsPerPageReview);
        const currentReviewItems = reviewHistory.slice((currentPageReview - 1) * itemsPerPageReview, currentPageReview * itemsPerPageReview);
      
        const handleReviewPageChange = (pageNumber) => {
          setCurrentPageReview(pageNumber);
        };

  const [getReviewableItems, { isLoadingGetReviewableItems }] = useGetReviewableItemsMutation();
  const [getReviewHistory, { isLoadingGetReviewHistory }] = useGetReviewHistoryMutation();
  const [updateReview, { isLoadingUpdateReview }] = useUpdateReviewMutation();
  const [createReview, { isLoadingCreateReview }] = useCreateReviewMutation();
  const [deleteReview, { isLoadingDeleteReview }] = useDeleteReviewMutation();
  

  const handleArrowClick = () => {
    navigate('/user');
  };

  
            
           

  const handleNameChange = (e) => {
    const newName = e.target.value.replace(/\d/g, "");
    setName(newName);
    const isValid = newName.length >= 1 ;
    setIsValidName(isValid);

  };

  const handleNameBlur = () => {
    setIsBlurName(true);
  };

  const handleNameChangeEdit = (e) => {
    const newName = e.target.value.replace(/\d/g, "");
    setNameEdit(newName);
    const isValid = newName.length >= 1 ;
    setIsValidNameEdit(isValid);

  };

  const handleNameBlurEdit = () => {
    setIsBlurNameEdit(true);
  };

    const handleReviewChange = (e) => {
    const newName = e.target.value;
    setReview(newName);
    const isValid = newName.length >= 25 ;
    setIsValidReview(isValid);

  };

  const handleReviewBlur = () => {
    setIsBlurReview(true);
  };

  const handleReviewChangeEdit = (e) => {
    const newName = e.target.value;
    setReviewEdit(newName);
    const isValid = newName.length >= 25 ;
    setIsValidReviewEdit(isValid);

  };

  const handleReviewBlurEdit = () => {
    setIsBlurReviewEdit(true);
  };


  useLayoutEffect(() => {
    const updateHeight = () => {
      const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;
      const detailsHeight = detailsRef.current ? detailsRef.current.offsetHeight : 0;

      const windowHeight = window.innerHeight;


      setRemainingHeight(windowHeight - 130 - 30 - headerHeight);
    };

    // Initial calculation
    // updateHeight();
    setTimeout(updateHeight, 500);

    // Recalculate on window resize
    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  useEffect(() => {
    setName(userName);
    setIsValidName(true);
  }, [userName]);

  useEffect(() => {
    // Map through the response and merge data from products
const updatedOrderItems = orderItemsPreImage.map((orderItem) => {
  // Find the product matching the order item by title
  const matchingProduct = products.find(
    (product) => product.title === orderItem.orderItemName
  );

  // Add the product image URL if a match is found
  return {
    ...orderItem,
    productImageURL: matchingProduct ? matchingProduct.image02 : null, // Replace 'imageURL' with your actual field name if different
  };
});

setOrderItems(updatedOrderItems);
    
  }, [products, orderItemsPreImage]);

  useEffect(() => {
    // Map through the response and merge data from products
const updatedReviewHistory = reviewHistoryPreImage.map((reviewItem) => {
  // Find the product matching the order item by title
  const matchingProduct = products.find(
    (product) => product.title === reviewItem.itemName
  );

  // Add the product image URL if a match is found
  return {
    ...reviewItem,
    productImageURL: matchingProduct ? matchingProduct.image02 : null, // Replace 'imageURL' with your actual field name if different
  };
});

setReviewHistory(updatedReviewHistory);
    
  }, [products, reviewHistoryPreImage]);

  const fetchData = async () => {
    try {
      if (activeTab === 'orders') {
        // const response = await getReviewableItems({ userID }).unwrap();

        // setOrderItems(response);

        const response = await getReviewableItems({ userID }).unwrap();
        setOrderItemsPreImage(response);
      } else {
        const response = await getReviewHistory({ userID }).unwrap();
        setReviewHistoryPreImage(response);


      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSubmitReview = async () => {
    if (review.length < 25) {
      setError('Review must be at least 25 characters long');
      return;
    }

    try {
        let orderItemID = selectedItem._id;
        let orderID = selectedItem.orderID;
        let displayName = name;
        let merchantTransactionId = selectedItem.merchantTransactionId;
        let itemName = selectedItem.orderItemName;

        const response = await createReview({ userID, orderItemID, orderID, rating, review, displayName, merchantTransactionId, itemName }).unwrap();
        window.location.reload();
        if (response.ok) {
        
        setIsModalOpen(false);
        setSelectedItem(null);
        setRating(0);
        setReview('');
        fetchData();
        setName(userName);
        setIsValidName(true);
        setIsBlurName(false);
      }
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };
  const handleCloseModal  = () => {
    setShowModal(false);
    setName(userName);
    setIsValidName(true);
    setIsBlurName(false);
    setSelectedItem(null);
    setRating(0);
    setReview('');
  };

  const handleCloseModalEdit  = () => {
    setShowModalEdit(false);
    setSelectedReview(null);
    setNameEdit('');
    setRatingEdit('');
    setReviewEdit('');
  };

  const handleCloseModalDelete  = () => {
    setShowModalDelete(false);
    setSelectedReview(null);
  };

  

  const handleUpdateReview = async () => {
    try {
      const _id = selectedReview._id;
      const displayName = nameEdit;
      const rating = ratingEdit;
      const review = reviewEdit;
  
      // Assuming `updateReview` is a Redux Toolkit query or similar async function
      const response = await updateReview({ _id, displayName, rating, review }).unwrap();
  
      // Reload the page to reflect the updated data
      window.location.reload();
    } catch (error) {
      console.error("Failed to update review:", error);
    }
  };
  

  const handleDeleteReview = async () => {


    try {
      const _id = selectedReview._id;
      const orderItemID = selectedReview.orderItemID;
  
      const response = await deleteReview({ _id, orderItemID}).unwrap();
  
      // Reload the page to reflect the updated data
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete review:", error);
    }

  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  return (
    <div className='review__page'>
    <Container>
    <div>
    <div className='reviewheader' ref={headerRef}>
    <div className="d-flex align-items-center mb-3">
<div className="arrow">
<FaArrowLeft onClick={handleArrowClick} />
</div>
<div className="profile flex-grow-1">
<h1 className="text-center my-auto">Review Items</h1>
</div>
</div>
    </div>
    {/* <div className="w-full max-w-4xl mx-auto "> */}
    <div className="review__page-list">
    
      {/* Tabs */}
      <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="review__tab-header ">
        <Tab eventKey="orders" title="Order Items">
          <div className="review__item-list">

          {orderItems.length === 0 ? (
            <Container>
            <Row className='pl-1 pr-1 '>
                    <div className="orowitem">
                  <div className="noorderyet_card">
                  {/* <div class="card-title">Special Offer!</div> */}
                  <span>
                  <div className="ocard-content px-4">No Items To Review</div>
                  </span>
              </div>
              </div>
                  </Row>
            </Container>

) : (
  <>

            {currentItems.map((item) => (


<ListGroupItem 
key={item._id}
onClick={() => {
  setSelectedItem(item);
  setShowModal(true);
  setReview('');
  setRating(0);
}}
className="p-2 border-0 review_item"
>
{/* Title and Status Row */}
<Row className="mb-1 px-2 align-items-center">
  <Col className="align-items-center">
    <h6 className="h6 fw-semibold text-dark mb-0">
      {item.orderItemName}
    </h6>
  </Col>
  <Col xs="auto" className="align-items-center">
    <Badge bg="success" className="px-3 py-1 text-white rounded-pill">
      Delivered
    </Badge>
  </Col>
</Row>

{/* Image and Details Row */}
<Row className="px-2" xs="auto">
  {/* Product Image */}
  <Col xs={5} md={3} className="justify-content-start mb-1 mt-2 mb-md-0">
          <img
            src={item.productImageURL}
            alt={item.orderItemName}
            style={{ width: '100%', height: 'auto', maxWidth: '110px' }} // Set max width for control
          />
        </Col>

  {/* Order Details */}
  <Col xs={7} md={9}>
    {/* Order Info */}
    <Row className="align-items-center mb-1">
      <Col xs="auto">
        <Package className="w-5 h-5 text-gray-400 me-1" style={{ fontSize: "1.5em", strokeWidth: 2 }}/>
      </Col>
      <Col>
      <p className="fs-9 text-gray-500 mb-0 fw-bold" style={{ fontSize: '14px' }}>Order</p>
        <p className="fs-7 font-medium text-gray-900 mb-0" style={{ fontSize: '14px' }}>{item.merchantTransactionId}</p>
      </Col>
    </Row>



    {/* Order Date */}
    <Row className="align-items-center mb-1">
      <Col xs="auto">
        <Calendar className="w-5 h-5 text-gray-400 me-1" style={{ fontSize: "1.5em", strokeWidth: 2 }}/>
      </Col>
      <Col>
        <p className="text-sm text-gray-500 mb-0 fw-bold" style={{ fontSize: '14px' }}>Order Date</p>
        <p className="text-sm font-medium text-gray-900 mb-0" style={{ fontSize: '14px' }}>
          {formatDate(item.paidOrderDetails[0].createdAt)}
        </p>
      </Col>
    </Row>

    {/* Estimated Delivery */}
    <Row className="align-items-center">
      <Col xs="auto">
        <Truck className="w-5 h-5 text-gray-400 me-1" style={{ fontSize: "1.5em", strokeWidth: 2 }}/>
      </Col>
      <Col>
        <p className="text-sm text-gray-500 mb-0 fw-bold" style={{ fontSize: '14px' }}>Delivery Date</p>
        <p className="text-sm font-medium text-gray-900 mb-0" style={{ fontSize: '14px' }}>
          {formatDate(item.paidOrderDetails[0].deliveryDate)}
        </p>
      </Col>
    </Row>
  </Col>
</Row>
</ListGroupItem>



             

            ))}

                          {/* Pagination controls */}
{/* <div className="pagination-controls pagination-review d-flex justify-content-center position-fixed bottom-0 start-50 translate-middle-x pb-5 w-100 bg-white"> */}
<div
  className="pagination-controls pagination-review d-flex justify-content-center position-fixed bottom-0 start-50 translate-middle-x w-100 bg-white"
>
  <Button
    style={{
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0, // Removes any default padding that may offset the content
      width: '30px', // Ensure the button is square for symmetry
    }}
    disabled={currentPage === 1}
    onClick={() => handlePageChange(currentPage - 1)}
  >
    {"<"}
  </Button>
  <span className="mx-3">{currentPage} / {totalPages}</span>
  <Button
    style={{
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      width: '30px',
    }}
    disabled={currentPage === totalPages}
    onClick={() => handlePageChange(currentPage + 1)}
  >
    {">"}
  </Button>
</div>

            </>
            )}
          </div>
        </Tab>
        <Tab eventKey="history" title="Review History">
          <div className="review__item-list">
          {reviewHistory.length === 0 ? (
            <Container>
            <Row className='pl-1 pr-1 '>
                    <div className="orowitem">
                  <div className="noorderyet_card">
                  {/* <div class="card-title">Special Offer!</div> */}
                  <span>
                  <div className="ocard-content px-4">No Reviews</div>
                  </span>
              </div>
              </div>
                  </Row>
            </Container>

) : (
  <>
            {currentReviewItems.map((review) => (
              <ListGroupItem 
key={review._id}

className="p-2 border-0 review_item"
>
{/* Title and Status Row */}
<Row className="mb-1 px-2 align-items-center d-flex">
  <Col xs="8" >
  <div className='justify-content-left'>
  <h6 className="h6 fw-semibold text-dark mb-0">
      {review.itemName}
    </h6>
  </div>
  </Col>
  <Col xs="4" >
    <div className="d-flex justify-content-end">
      <Edit className="me-3" 
      onClick={() => {
        setSelectedReview(review);
        setShowModalEdit(true);
        setNameEdit(review.displayName);
        setRatingEdit(review.rating);
        setReviewEdit(review.review);
        setEditReviewOriginal(review.review);
        setEditDisplayNameOriginal(review.displayName);
        setEditRatingOriginal(review.rating);
        setIsValidNameEdit(true);
      }}/> 
      <Trash2 
      onClick={() => {
        setSelectedReview(review);
        setShowModalDelete(true);
      }}/> 
    </div>
  </Col>
</Row>



{/* Image and Details Row */}
<Row className="px-2" xs="auto">
  {/* Product Image */}
  <Col xs={5} md={3} className="justify-content-start mb-1 mt-2 mb-md-0">
          <img
            src={review.productImageURL}
            alt={review.orderItemName}
            style={{ width: '100%', height: 'auto', maxWidth: '110px' }} // Set max width for control
          />
        </Col>

  {/* Order Details */}
  <Col xs={7} md={9}>
    {/* Order Info */}
    <Row className="align-items-center mb-1">

      <Col>
      <p className="fs-9 text-gray-500 mb-0 fw-bold" style={{ fontSize: '14px' }}>Rating</p>
        {/* <p className="fs-7 font-medium text-gray-900 mb-0" style={{ fontSize: '14px' }}> */}
        <div>
      {[1, 2, 3, 4, 5].map((star) => (
        <span key={star} style={{ cursor: 'pointer' }}>
          {star <= review.rating ? (
            <SolidStar className="text-yellow-400" stroke="Black" fill="#daa927" width={20} />
          ) : (
            <OutlineStar className="text-gray-400"   width={20} />
          )}
        </span>
      ))}
    </div>
        {/* </p> */}
      </Col>
    </Row>

    <Row>

    </Row>



    {/* Order Date */}
    <Row className="align-items-center mb-1">
      <Col>
        <p className="text-sm text-gray-500 mb-0 fw-bold" style={{ fontSize: '14px' }}>Review</p>
        <p className="text-sm font-medium text-gray-900 mb-0" style={{ fontSize: '14px' }}>
          {review.review}
        </p>
      </Col>
    </Row>

  </Col>
</Row>
</ListGroupItem>
            ))}

                          {/* Pagination controls */}
{/* <div className="pagination-controls pagination-review d-flex justify-content-center position-fixed bottom-0 start-50 translate-middle-x pb-5 w-100 bg-white"> */}
<div
  className="pagination-controls pagination-review d-flex justify-content-center position-fixed bottom-0 start-50 translate-middle-x w-100 bg-white"
>
  <Button
    style={{
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0, // Removes any default padding that may offset the content
      width: '30px', // Ensure the button is square for symmetry
    }}
    disabled={currentPageReview === 1}
    onClick={() => handleReviewPageChange(currentPageReview - 1)}
  >
    {"<"}
  </Button>
  <span className="mx-3">{currentPageReview} / {totalReviewPages}</span>
  <Button
    style={{
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      width: '30px',
    }}
    disabled={currentPageReview === totalReviewPages}
    onClick={() => handleReviewPageChange(currentPageReview + 1)}
  >
    {">"}
  </Button>
</div>
          
                      </>
                    )}
                    </div>
        </Tab>
      </Tabs>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedItem?.orderItemName || 'Write a Review'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="privacy-policy">


<BootstrapForm.Group className="mt-0" controlId="emailForm">

              <div className="flex justify-center space-x-2">



    <Row className='mb-0'> 
      
      <BootstrapForm.Label>
      Reviewer Name:&nbsp;
            {isValidName && (
              <FaCheckCircle style={{ color: "green", marginLeft: "5px" }} />
            )}
            {!isValidName && isBlurName && (
              <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />
            )}
          </BootstrapForm.Label>
      </Row>
      <Row className='mb-0 px-2'>
              <input
            type="text"
            name="firstNameForm"
            className="form-control"
            placeholder="Enter Name"
            value={name}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            required
            style={{
              border: isValidName
                ? "1px solid #198754"
                : !isValidName && isBlurName
                ? "1px solid #ea868f"
                : "",
            }}
          />
        
        {!isValidName && isBlurName ? (
          <span className="error-message">Please enter a valid Name</span>
        ) : null}

</Row>
    <Row className='mb-0 mt-3'>
      <BootstrapForm.Label>
      Rating:&nbsp;
            {rating > 0  && (
              <FaCheckCircle style={{ color: "green", marginLeft: "5px" }} />
            )}
          </BootstrapForm.Label>
    </Row>
    <Row>
    <div>
      {[1, 2, 3, 4, 5].map((star) => (
        <span key={star} onClick={() => setRating(star)} style={{ cursor: 'pointer' }}>
          {star <= rating ? (
            <SolidStar className="text-yellow-400" stroke="Black" fill="#daa927" width={20} />
          ) : (
            <OutlineStar className="text-gray-400"   width={20} />
          )}
        </span>
      ))}
    </div>
    </Row>
              
              <Row className='mb-0 mt-3'>
              <BootstrapForm.Label>
              Review:&nbsp;
              {isValidReview && (
              <FaCheckCircle style={{ color: "green", marginLeft: "5px" }} />
            )}
            {!isValidReview && review.length > 1 && (
              <>
              <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />
              &nbsp;
              <span style={{ color: "red", fontSize: "0.9em" }}>Review must be longet than 25 characters ({review.length} / 25)`</span>
              </>
            )}
            </BootstrapForm.Label>
              </Row>
              <Row className='px-2'>
               
              <textarea
                className="w-full p-2 border rounded-lg"
                rows={4}
                value={review}
                onChange={handleReviewChange}
                onBlur={handleReviewBlur}
                placeholder="Write your review (minimum 25 characters)"
                style={{
                  border: isValidReview
                    ? "1px solid #198754"
                    : !isValidReview && isBlurReview
                    ? "1px solid #ea868f"
                    : "",
                }}
              />
              {error && <p className="text-red-500 text-sm">{error}</p>}
             
              </Row>
              </div>
        </BootstrapForm.Group>


</div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={handleSubmitReview} disabled={!(isValidName && rating > 0 && review.length > 24)}
        >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedReview?.itemName || 'Write a Review'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="privacy-policy">


<BootstrapForm.Group className="mt-0" controlId="emailForm">

              <div className="flex justify-center space-x-2">
    <Row className='mb-0'> 
    <BootstrapForm.Label>
      Reviewer Name:&nbsp;
            {isValidNameEdit && (
              <FaCheckCircle style={{ color: "green", marginLeft: "5px" }} />
            )}
            {!isValidNameEdit && isBlurNameEdit && (
              <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />
            )}
          </BootstrapForm.Label>
      </Row>
      <Row className='mb-0 px-2'>
              <input
            type="text"
            name="firstNameForm"
            className="form-control"
            placeholder="Enter Name"
            value={nameEdit}
            onChange={handleNameChangeEdit}
            onBlur={handleNameBlurEdit}
            required
            style={{
              border: isValidNameEdit
                ? "1px solid #198754"
                : !isValidNameEdit && isBlurNameEdit
                ? "1px solid #ea868f"
                : "",
            }}
          />
        
        {!isValidNameEdit && isBlurNameEdit ? (
          <span className="error-message">Please enter a valid Name</span>
        ) : null}

</Row>
    <Row className='mb-0 mt-3'>
    <BootstrapForm.Label>
      Rating:&nbsp;
            {ratingEdit > 0  && (
              <FaCheckCircle style={{ color: "green", marginLeft: "5px" }} />
            )}
          </BootstrapForm.Label>
    </Row>
    <Row>
    <div>
      {[1, 2, 3, 4, 5].map((star) => (
        <span key={star} onClick={() => setRatingEdit(star)} style={{ cursor: 'pointer' }}>
          {star <= ratingEdit ? (
            <SolidStar className="text-yellow-400" stroke="Black" fill="#daa927" width={20} />
          ) : (
            <OutlineStar className="text-gray-400"   width={20} />
          )}
        </span>
      ))}
    </div>
    </Row>
              
              <Row className='mb-0 mt-3'>
              <BootstrapForm.Label>
              Review:&nbsp;
              {isValidReviewEdit && (
              <FaCheckCircle style={{ color: "green", marginLeft: "5px" }} />
            )}
            {!isValidReviewEdit && reviewEdit.length > 1 && (
              <>
              <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />
              &nbsp;
              <span style={{ color: "red", fontSize: "0.9em" }}>Review must be longet than 25 characters ({reviewEdit.length} / 25)`</span>
              </>
            )}
            </BootstrapForm.Label>
              </Row>
              <Row className='px-2'>
               
              <textarea
                className="w-full p-2 border rounded-lg"
                rows={4}
                value={reviewEdit}
                onChange={handleReviewChangeEdit}
                onBlur={handleReviewBlurEdit}
                placeholder="Write your review (minimum 25 characters)"
              />
              {error && <p className="text-red-500 text-sm">{error}</p>}
             
              </Row>
              </div>
        </BootstrapForm.Group>

</div>
        </Modal.Body>
        <Modal.Footer>
        {/* <Button variant="primary" onClick={handleSubmitReview} disabled={!(isValidNameEdit && ratingEdit > 0 && reviewEdit > 24 && (editDisplayNameOriginal === nameEdit && editReviewOriginal === reviewEdit && editRatingOriginal === ratingEdit))}
        >
            Update
          </Button> */}
          <Button
  variant="primary"
  onClick={handleUpdateReview}
  disabled={
    !isValidNameEdit || 
    reviewEdit.length < 24 || 
    (editDisplayNameOriginal === nameEdit && 
     editReviewOriginal === reviewEdit && 
     editRatingOriginal === ratingEdit)
  }
>
  Update
</Button>

        </Modal.Footer>
      </Modal>

      {/* <Modal show={showModalDelete} onHide={handleCloseModalDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="privacy-policy">

    <Row className='mb-0'> 
      <h5>Are you sure you wan to delete this review?</h5>
      </Row>
</div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={handleDeleteReview}
        >
            Submit
          </Button>
        </Modal.Footer>
      </Modal> */}

<Modal show={showModalDelete} onHide={handleCloseModalDelete}>
  <Modal.Header closeButton>
    <Modal.Title>Delete Review</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="privacy-policy">
      <Row className="mb-0">
        <h5>Are you sure you want to delete this review?</h5>
      </Row>
    </div>
  </Modal.Body>
  <Modal.Footer>
    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
      {/* Left: Delete Button */}
      <Button variant="danger" onClick={handleDeleteReview}>
        Delete
      </Button>
      {/* Right: Cancel Button */}
      <Button variant="secondary" onClick={handleCloseModalDelete}>
        Cancel
      </Button>
    </div>
  </Modal.Footer>
</Modal>


    </div>

    </div>
</Container>
</div>
  );
};

export default ReviewItems;
