// import Hero from '../components/Hero';
// import { useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import products from "../assets/data/products";
// import ProductCard from "../components/ProductCard";

// import { useSelector } from 'react-redux';
//  //import { useNavigate } from 'react-router-dom';

//  import "../styles/home.css";
//  import "../styles/background.css";
  

// const HomeScreen = () => {

//   const [pageNumber] = useState(0);
//   const { userInfo } = useSelector((state) => state.auth);

//   const searchedProduct = products;

//   const productPerPage = 8;
//   const visitedPage = pageNumber * productPerPage;
//   const displayPage = searchedProduct.slice(
//     visitedPage,
//     visitedPage + productPerPage
//   );
//   return (
// <div className={` ${!userInfo ? 'hero-background' : ''}`}>
//     {userInfo ? (
//       <div className="homelayout">
//       <Container >
//         <Row className='pl-1 pr-1 '>
//           <div className="rowitem">
//         <div className="home_card">
//         {/* <div class="card-title">Special Offer!</div> */}
//         <div className="card-content">Get Free Delivery when you order 3 or more items</div>
//     </div>
//     </div>
//         </Row>
//         <Row >
//           {displayPage.map((item) => (
//             <Col
//               lg="3"
//               md="4"
//               sm="6"
//               xs="6"
//               key={item.id}
//               className=" mt-4"
//             >
//               <ProductCard item={item} />
//             </Col>
//           ))}
//         </Row>
//       </Container>
//     </div>
//     ) : (
//       <div className='welcomebanner'>
//       <Hero />
//       </div>
//     )}
// </div>
//   );
  
//   // return <Hero />;
// };
// export default HomeScreen;

import Hero from '../components/Hero';
import { useState, useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProductCard from "../components/ProductCard";
import { useSelector } from 'react-redux';
import { ProductContext } from '../context/ProductContext';
import Loader from '../components/Loader';
import "../styles/home.css";
import "../styles/background.css";

const HomeScreen = () => {
  const [pageNumber] = useState(0);
  const { userInfo } = useSelector((state) => state.auth);
  const [loadingProducts, setLoadingProducts] = useState(true);


  
  // Get context with safety check
  const context = useContext(ProductContext);

  
  // Defensive programming: provide defaults if context is undefined
  const { products = [], loading, error } = context || {};

   useEffect(() => {
    if (products) {
      setLoadingProducts(false);
    }
  }, [products]);



  const productPerPage = 8;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = products.slice(
    visitedPage,
    visitedPage + productPerPage
  );

  // If not logged in, show hero section
  if (!userInfo) {
    return (
      <div className="hero-background">
        <div className="welcomebanner">
          <Hero />
        </div>
      </div>
    );
  }

  // Show loading state while fetching products
  if (loadingProducts) {
    return (
      <div className="homelayout">
      <div style={{ display: "flex", justifyContent: "center" , paddingTop: '10px'}}>
        <Loader animation="border" />
      </div>
      </div>
    );
  }

  if (!products || products.length === 0) {
    return (
      <div className="homelayout">
      <div style={{ display: "flex", justifyContent: "center" , paddingTop: '10px'}}>
        <Loader animation="border" />
      </div>
      </div>
    );
  }

  // Show error if products fetch failed
  if (error) {
    return (
      <div className="homelayout">
        <Container>
          <div className="text-center py-5 text-danger">
            Error loading products: {error}
          </div>
        </Container>
      </div>
    );
  }

  return (
    <div className="homelayout">
      <Container>
        <Row className="pl-1 pr-1">
          <div className="rowitem">
            <div className="home_card">
              <div className="card-content">
                Get Free Delivery when you order 3 or more items
              </div>
            </div>
          </div>
        </Row>
        <Row>
          {displayPage.map((item) => (
            <Col
              lg="3"
              md="4"
              sm="6"
              xs="6"
              key={item.id}
              className="mt-4"
            >
              <ProductCard item={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default HomeScreen;

// import Hero from '../components/Hero';
// import { useState, useContext, useEffect } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import ProductCard from "../components/ProductCard";
// import { useSelector } from 'react-redux';
// import { ProductContext } from '../context/ProductContext';
// import Loader from '../components/Loader';
// import "../styles/home.css";
// import "../styles/background.css";

// const HomeScreen = () => {
//   const { userInfo } = useSelector((state) => state.auth);
//   const context = useContext(ProductContext);

//   const { products = [], loading, error } = context || {};
//   const pageNumber = 0;
//   const productPerPage = 8;
//   const visitedPage = pageNumber * productPerPage;
//   const displayPage = products.slice(visitedPage, visitedPage + productPerPage);

//   if (!userInfo) {
//     return (
//       <div className="hero-background">
//         <div className="welcomebanner">
//           <Hero />
//         </div>
//       </div>
//     );
//   }

//   if (loading) {
//     return (
//       <div className="homelayout">
//         <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
//           <Loader animation="border" />
//         </div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div className="homelayout">
//         <Container>
//           <div className="text-center py-5 text-danger">
//             Error loading products: {error}
//           </div>
//         </Container>
//       </div>
//     );
//   }

//   if (!products || products.length === 0) {
//     return (
//       <div className="homelayout">
//         <Container>
//           <div className="text-center py-5">
//             No products available.
//           </div>
//         </Container>
//       </div>
//     );
//   }

//   return (
//     <div className="homelayout">
//       <Container>
//         <Row className="pl-1 pr-1">
//           <div className="rowitem">
//             <div className="home_card">
//               <div className="card-content">
//                 Get Free Delivery when you order 3 or more items
//               </div>
//             </div>
//           </div>
//         </Row>
//         <Row>
//           {displayPage.map((item) => (
//             <Col lg="3" md="4" sm="6" xs="6" key={item.id} className="mt-4">
//               <ProductCard item={item} />
//             </Col>
//           ))}
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default HomeScreen;
