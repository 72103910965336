import { apiSlice } from './apiSlice';
const REVIEW_URL = '/reviews';

export const reviewApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({


    getReviewableItems: builder.mutation({
      query: (data) => ({
        url: `${REVIEW_URL}/reviewable-items`,
        method: 'POST',
        body: data,
      }),
    }),

    getReviewHistory: builder.mutation({
      query: (data) => ({
        url: `${REVIEW_URL}/history`,
        method: 'POST',
        body: data,
      }),
    }),

    createReview: builder.mutation({
      query: (data) => ({
        url: `${REVIEW_URL}/create`,
        method: 'POST',
        body: data,
      }),
    }),

    updateReview: builder.mutation({
      query: (data) => ({
        url: `${REVIEW_URL}/update`,
        method: 'POST',
        body: data,
      }),
    }),

    deleteReview: builder.mutation({
      query: (data) => ({
        url: `${REVIEW_URL}/delete`,
        method: 'POST',
        body: data,
      }),
    }),

    getReviews: builder.mutation({
      query: (data) => ({
        url: `${REVIEW_URL}/getReviews`,
        method: 'POST',
        body: data,
      }),
    }),



  }),
});

export const {
useGetReviewableItemsMutation,
useGetReviewHistoryMutation,
useCreateReviewMutation,
useUpdateReviewMutation,
useDeleteReviewMutation,
useGetReviewsMutation
} = reviewApiSlice;