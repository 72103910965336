import { apiSlice } from './apiSlice';
const ORDERS_URL = '/couponsbase';

export const couponsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({


    getAllCoupons: builder.mutation({
      query: (data) => ({
        url: `${ORDERS_URL}/getallcoupons`,
        method: 'GET',
        body: data,
      }),
    }),

    createNewCoupon: builder.mutation({
      query: (data) => ({
        url: `${ORDERS_URL}/createnewcoupon`,
        method: 'POST',
        body: data,
      }),
    }),

    editCoupon: builder.mutation({
      query: (data) => ({
        url: `${ORDERS_URL}/editcoupon`,
        method: 'POST',
        body: data,
      }),
    }),

    GetActiveUponRegistrationCoupon: builder.mutation({
      query: (data) => ({
        url: `${ORDERS_URL}/getactivecoupons`,
        method: 'GET',
        body: data,
      }),
    }),

    changeCouponStatus: builder.mutation({
      query: (data) => ({
        url: `${ORDERS_URL}/changecouponstatus`,
        method: 'POST',
        body: data,
      }),
    }),

    CheckIfCodeIsValid: builder.mutation({
      query: (data) => ({
        url: `${ORDERS_URL}/checkcode`,
        method: 'POST',
        body: data,
      }),
    }),

    ChangeCouponStatusToUsed: builder.mutation({
      query: (data) => ({
        url: `${ORDERS_URL}/changestatus`,
        method: 'POST',
        body: data,
      }),
    }),


    

    


  }),
});

export const {
    useGetAllCouponsMutation,
    useGetActiveUponRegistrationCouponMutation,
    useCreateNewCouponMutation,
    useEditCouponMutation,
    useChangeCouponStatusMutation,
    useCheckIfCodeIsValidMutation,
    useChangeCouponStatusToUsedMutation
} = couponsApiSlice;