// import "../styles/product-card.css";

// // import productImg from "../../../assets/images/product_2.1.jpg";

// import { useDispatch } from "react-redux";
// import { cartActions } from "../slices/cartSlice";
// import { BsPlus, BsDash, BsTrash } from 'react-icons/bs';
// import { StarIcon as SolidStar } from '@heroicons/react/solid';

// import { Link } from "react-router-dom";

// const ProductCard = (props) => {
//   const { id, title, image01, image02, price, extraIngredients } = props.item;
//   const dispatch = useDispatch();

//   const addToCart = () => {
//     dispatch(
//       cartActions.addItem({
//         id,
//         title,
//         image01,
//         image02,
//         price,
//         extraIngredients
//       })
//     );
//   };

//   return (
//     <div className="product__item d-flex flex-column justify-content-between">
//       <div className="product__content">
//         <img className="product__img w-100" src={image02} alt="Pizza" />
//       <div className='meal_name mt-2'>
//           {/* <Link to={`/meals/${id}`}>{title}</Link> */}
//           <span>{title}</span>
//           </div>
//       </div>
//       {/* <div className="d-flex flex-column align-items-center justify-content-between"> */}
//       <div className="d-flex align-items-center justify-content-between">  
//         <span className="product__price"> R {price}</span>
//         <button className="addTOCART__btn" onClick={addToCart}>
//         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//     <BsPlus />
//   </div>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ProductCard;

///////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
// import { StarIcon as SolidStar } from '@heroicons/react/solid';
// import React,  {useEffect} from "react";
// import { Card, Button } from "react-bootstrap";
// import { Star, Plus } from "lucide-react";
// import { useDispatch  } from "react-redux";
// import { cartActions } from "../slices/cartSlice";
// import { useGetReviewsMutation
// } from '../slices/reviewApiSlice';

// import "../styles/product-card.css";

// const ProductCard = ({ item = {} }) => {
//   const { id, title, image01, image02, price, extraIngredients, rating = 4.7, reviews = 124 } = item;

//   const [getReviews, { isLoadingGetReviews }] = useGetReviewsMutation();
//   const dispatch = useDispatch();

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//         const response = await getReviews().unwrap();
//         console.log(response);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

  

//   const handleAddToCart = () => {
//     if (typeof dispatch !== "undefined") {
//       dispatch(
//         cartActions.addItem({
//           id,
//           title,
//           image01,
//           image02,
//           price,
//           extraIngredients,
//         })
//       );
//     }
//   };



//   return (
//     <Card className="h-100 shadow-sm product__content product__item">
//       <div className="position-relative overflow-hidden px-auto">
//         <Card.Img 
//           variant="top" 
//           src={image02} 
//           alt={title} 
//           className="img-fluid " 
//           style={{ height: "100%", objectFit: "cover", transition: "transform 0.3s" }}
//         />

//       </div>

//       <Card.Body>
//         <Card.Title className=" meal_name" title={title}>
//           {title}
//         </Card.Title>
//         <div className="d-flex align-items-center mb-2 rating">
//         <SolidStar className="text-yellow-400 " stroke="Black" fill="#daa927" width={20} />{rating}
//           <span className="ms-2 reviews">
//             ({reviews} reviews)
//           </span>
//         </div>
//         <div className="d-flex justify-content-between align-items-center">
//           <span className="fs-5 fw-bold product__price">
//             R {price}
//           </span>
//           {/* <Button 
//             variant="primary" 
//             className="d-flex align-items-center justify-content-center p-2 rounded-circle"
//             onClick={handleAddToCart}
//           > */}
//             <Button className="addTOCART__btn" onClick={handleAddToCart}>
//             <Plus className="h-5 w-5" />
//           </Button>
//         </div>
//       </Card.Body>
//     </Card>
//   );

//   // return (
//   //   <Card className="h-100 shadow-sm">
//   //     <div className="position-relative overflow-hidden">
//   //       <Card.Img 
//   //         variant="top" 
//   //         src={image02} 
//   //         alt={title} 
//   //         className="img-fluid" 
//   //         style={{ height: "200px", objectFit: "cover", transition: "transform 0.3s" }}
//   //         onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
//   //         onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
//   //       />
//   //       <div
//   //         className="position-absolute top-0 start-0 end-0 bottom-0 bg-black"
//   //         style={{ opacity: "0", transition: "opacity 0.3s" }}
//   //         onMouseEnter={(e) => (e.currentTarget.style.opacity = "0.1")}
//   //         onMouseLeave={(e) => (e.currentTarget.style.opacity = "0")}
//   //       />
//   //     </div>

//   //     <Card.Body>
//   //       <Card.Title className="fs-6 fw-bold text-truncate" title={title}>
//   //         {title}
//   //       </Card.Title>
//   //       <div className="d-flex align-items-center mb-2">
//   //       <SolidStar className="text-yellow-400" stroke="Black" fill="#daa927" width={20} />{rating}
//   //         <span className="ms-2 text-muted fs-6">
//   //           ({reviews} reviews)
//   //         </span>
//   //       </div>
//   //       <div className="d-flex justify-content-between align-items-center">
//   //         <span className="fs-5 fw-bold text-dark">
//   //           R {typeof price === "number" ? price.toFixed(2) : "0.00"}
//   //         </span>
//   //         <Button 
//   //           variant="primary" 
//   //           className="d-flex align-items-center justify-content-center p-2 rounded-circle"
//   //           onClick={handleAddToCart}
//   //         >
//   //           <Plus className="h-5 w-5" />
//   //         </Button>
//   //       </div>
//   //     </Card.Body>
//   //   </Card>
//   // );
// };

// export default ProductCard;

///////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
// import { StarIcon as SolidStar } from "@heroicons/react/solid";
// import React, { useEffect, useState } from "react";
// import { Card, Button } from "react-bootstrap";
// import { Star, Plus } from "lucide-react";
// import { useDispatch } from "react-redux";
// import { cartActions } from "../slices/cartSlice";
// import { useGetReviewsMutation } from "../slices/reviewApiSlice";

// import "../styles/product-card.css";

// const ProductCard = ({ item = {} }) => {
//   const { id, title, image01, image02, price, extraIngredients} = item;

//   const [getReviews, { isLoading: isLoadingGetReviews }] = useGetReviewsMutation();
//   const dispatch = useDispatch();

//   // State to hold filtered reviews and calculated data
//   const [filteredReviews, setFilteredReviews] = useState([]);
//   const [averageRating, setAverageRating] = useState(0);

//   useEffect(() => {
//     fetchReviews();
//   }, []);

//   const fetchReviews = async () => {
//     try {
//       const response = await getReviews().unwrap();
//       console.log(response);
//       if (response && Array.isArray(response)) {
//         const productReviews = response.filter((review) => review.itemName === title); // Filter reviews for this product
//         setFilteredReviews(productReviews);

//         // Calculate the average rating
//         const totalRatings = productReviews.reduce((sum, review) => sum + review.rating, 0);
//         const avgRating = productReviews.length > 0 ? totalRatings / productReviews.length : 0;
//         setAverageRating(avgRating.toFixed(1)); // Round to 1 decimal place
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const handleAddToCart = () => {
//     if (typeof dispatch !== "undefined") {
//       dispatch(
//         cartActions.addItem({
//           id,
//           title,
//           image01,
//           image02,
//           price,
//           extraIngredients,
//         })
//       );
//     }
//   };

//   return (
//     <Card className="h-100 shadow-sm product__content product__item">
//       <div className="position-relative overflow-hidden px-auto">
//         <Card.Img
//           variant="top"
//           src={image02}
//           alt={title}
//           className="img-fluid"
//           style={{ width: "95%", objectFit: "cover", transition: "transform 0.3s" }}
//         />
//       </div>

//       <Card.Body>
//         <Card.Title className="meal_name" title={title}>
//           {title}
//         </Card.Title>
//         <div className="d-flex align-items-center mb-2 rating">
//           <SolidStar className="text-yellow-400" stroke="Black" fill="#daa927" width={20} />
//           {averageRating }
//           <span className="ms-2 reviews">({filteredReviews.length} reviews)</span>
//         </div>
//         <div className="d-flex justify-content-between align-items-center">
//           <span className="fs-5 fw-bold product__price">R {price}</span>
//           <Button className="addTOCART__btn" onClick={handleAddToCart}>
//             <Plus className="h-5 w-5" />
//           </Button>
//         </div>
//       </Card.Body>
//     </Card>
//   );
// };

// export default ProductCard;

///////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
// import { StarIcon as SolidStar } from "@heroicons/react/solid";
// import React, { useEffect, useState } from "react";
// import { Card, Button } from "react-bootstrap";
// import { Star, Plus } from "lucide-react";
// import { useDispatch } from "react-redux";
// import { cartActions } from "../slices/cartSlice";
// import { useGetReviewsMutation } from "../slices/reviewApiSlice";

// import "../styles/product-card.css";

// const ProductCard = ({ item = {} }) => {
//   const { id, title, image01, image02, price, extraIngredients} = item;

//   const [getReviews, { isLoading: isLoadingGetReviews }] = useGetReviewsMutation();
//   const dispatch = useDispatch();

//   // State to hold filtered reviews and calculated data
//   const [filteredReviews, setFilteredReviews] = useState([]);
//   const [averageRating, setAverageRating] = useState(0);

//   useEffect(() => {
//     fetchReviews();
//   }, []);

//   const fetchReviews = async () => {
//     try {
//       const response = await getReviews().unwrap();
//       console.log(response);
//       if (response && Array.isArray(response)) {
//         const productReviews = response.filter((review) => review.itemName === title); // Filter reviews for this product
//         setFilteredReviews(productReviews);

//         // Calculate the average rating
//         const totalRatings = productReviews.reduce((sum, review) => sum + review.rating, 0);
//         const avgRating = productReviews.length > 0 ? totalRatings / productReviews.length : 0;
//         setAverageRating(avgRating.toFixed(1)); // Round to 1 decimal place
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const handleAddToCart = () => {
//     if (typeof dispatch !== "undefined") {
//       dispatch(
//         cartActions.addItem({
//           id,
//           title,
//           image01,
//           image02,
//           price,
//           extraIngredients,
//         })
//       );
//     }
//   };

//   return (
// <Card className="h-100 shadow-sm product__content product__item">
//   <div className="card-image-container">
//     <Card.Img
//       variant="top"
//       src={image02}
//       alt={title}
//       className="product-image"
//     />
//   </div>
//   <Card.Body>
//         <Card.Title className="meal_name" title={title}>
//           {title}
//         </Card.Title>
//         <div className="d-flex align-items-center mb-2 rating">
//           <SolidStar className="text-yellow-400" stroke="Black" fill="#daa927" width={20} />
//           {averageRating }
//           <span className="ms-2 reviews">({filteredReviews.length} reviews)</span>
//         </div>
//         <div className="d-flex justify-content-between align-items-center">
//           <span className="fs-5 fw-bold product__price">R {price}</span>
//           <Button className="addTOCART__btn" onClick={handleAddToCart}>
//             <Plus className="h-5 w-5" />
//           </Button>
//         </div>
//       </Card.Body>

// </Card>

//   );
// };

// export default ProductCard;

import { StarIcon as SolidStar } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { Card, Button, Modal, ListGroupItem } from "react-bootstrap";
import { Star, Plus } from "lucide-react";
import { useDispatch } from "react-redux";
import { cartActions } from "../slices/cartSlice";
import { useGetReviewsMutation } from "../slices/reviewApiSlice";
import { Weight, MessageCircle } from 'lucide-react';
import Loader from '../components/Loader';
import {  Badge, Row, Col } from 'react-bootstrap';
import { StarIcon as OutlineStar } from '@heroicons/react/outline';

import "../styles/product-card.css";

const ProductCard = ({ item = {} }) => {
  const { id, title, image01, image02, price, extraIngredients, desc, weight } = item;

  const [getReviews, { isLoading: isLoadingGetReviews }] = useGetReviewsMutation();
  const dispatch = useDispatch();

  // State to hold filtered reviews and calculated data
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);

  // Modal state
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const response = await getReviews().unwrap();
      if (response && Array.isArray(response)) {
        const productReviews = response.filter((review) => review.itemName === title); // Filter reviews for this product
        // Sort reviews by updatedAt field (latest first)
        const sortedReviews = productReviews.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setFilteredReviews(sortedReviews);

        // Calculate the average rating
        const totalRatings = sortedReviews.reduce((sum, review) => sum + review.rating, 0);
        const avgRating = sortedReviews.length > 0 ? totalRatings / sortedReviews.length : 0;
        setAverageRating(avgRating.toFixed(1)); // Round to 1 decimal place
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddToCart = () => {
    if (typeof dispatch !== "undefined") {
      dispatch(
        cartActions.addItem({
          id,
          title,
          image01,
          image02,
          price,
          extraIngredients,
        })
      );
    }
  };

  const handleCardClick = () => {
    setShowModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

    // Show loading state while fetching products
    if (isLoadingGetReviews) {
      return (
        
        <div >
          <Loader animation="border" />
        </div>
        
      );
    }

  return (
    <>
      <Card
        className="h-100 shadow-sm product__content product__item"
        onClick={handleCardClick}
        style={{ cursor: "pointer" }} // Add cursor to indicate clickable
      >
        <div className="card-image-container">
          <Card.Img
            variant="top"
            src={image02}
            alt={title}
            className="product-image"
          />
        </div>
        <Card.Body>
          <Card.Title className="meal_name" title={title}>
            {title}
          </Card.Title>
          <div className="d-flex align-items-center mb-2 rating">
            <SolidStar
              className="text-yellow-400"
              stroke="Black"
              fill="#daa927"
              width={20}
            />
            {averageRating}
            <span className="ms-2 reviews">({filteredReviews.length} reviews)</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span className="fs-5 fw-bold product__price">R {price}</span>
            <Button className="addTOCART__btn"         onClick={(event) => {
          event.stopPropagation(); // Prevent event from propagating to the parent
          handleAddToCart(); // Call the button's click handler
        }}>
              <Plus className="h-5 w-5" />
            </Button>
          </div>
        </Card.Body>
      </Card>


<Modal show={showModal} onHide={handleCloseModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-overflow">
        <h6>{desc}</h6>

        <p>
  <span className="d-flex align-items-center">
    <div className="mb-0" style={{ fontStyle: "italic" }}>Weight:</div>
    <div style={{ marginLeft: "5px" }}>{weight}</div>
  </span>
</p>



        
        <hr />

        <span class="d-flex align-items-center">
  <h5 class="mb-0">Reviews</h5>
  <Badge bg="secondary" className="ms-2">
    {filteredReviews?.length || 0}
  </Badge>
</span>


                {filteredReviews && filteredReviews.length > 0 ? (
              filteredReviews.map((review) => (
                <ListGroupItem className="border-0 review__item reviewitem" >
                <div className="cart__item-info d-flex gap-4">
                  <div className="cart__product-info w-100 d-flex align-items-center gap-4 justify-content-between">
                    <div>
                    <span class="d-flex align-items-center">
                    <h6 className="cart__product-title mb-0 pr-3">{review.displayName}</h6>
                    </span>
                    <div>
                      
      {[1, 2, 3, 4, 5].map((star) => (
        <span key={star} >
          {star <= review.rating ? (
            <SolidStar className="text-yellow-400" stroke="Black" fill="#daa927" width={15} />
          ) : (
            <OutlineStar className="text-gray-400"   width={15} />
          )}
        </span>
      ))}
    </div>

                      
                      <p className=" d-flex align-items-center gap-3 justify-content-between review_words">
                        {review.review} 
                      </p>
                    </div>
                  </div>
                </div>
              </ListGroupItem>

              ))
            ) : (
              <div className="text-center text-muted italic">
                No reviews available for this product.
              </div>
            )}

      </Modal.Body>
    </Modal>
    </>
  );
};

export default ProductCard;





