// import { createContext, useState, useEffect } from 'react';
// import { useGetProductsMutation  } from "../slices/productApiSlice";


// export const ProductContext = createContext(null); // Initialize with null

// export const ProductProvider = ({ children }) => {
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [getProducts, { isLoadingGetProducts }] = useGetProductsMutation();

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         const response = await getProducts().unwrap();
//         console.log(response);
//         setProducts(response);
//         setLoading(false);
//       } catch (err) {
//         setError(err.message);
//         setLoading(false);
//       }
//     };

//     fetchProducts();
//   }, []);

//     // Provide an initial value object
//     const value = {
//         products,
//         loading,
//         error,
//       };

//   return (
//     <ProductContext.Provider value={{ value }}>
//       {children}
//     </ProductContext.Provider>
//   );
// };

//////////////////////////////////////////////////////////////////////////////

// import { createContext, useState, useEffect } from 'react';
// import { useGetProductsMutation } from "../slices/productApiSlice";

// export const ProductContext = createContext(null);

// export const ProductProvider = ({ children }) => {
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [getProducts, { isLoadingGetProducts }] = useGetProductsMutation();

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         const response = await getProducts().unwrap();
//         setProducts(response);
//         setLoading(false);
//       } catch (err) {
//         setError(err.message);
//         setLoading(false);
//       }
//     };

//     fetchProducts();
//   }, [getProducts]);

//   // Create the value object
//   const value = {
//     products,
//     loading,
//     error,
//   };

//   return (
//     <ProductContext.Provider value={value}> {/* Remove the extra curly braces */}
//       {children}
//     </ProductContext.Provider>
//   );
// };


//////////////////////////////////////////////////////////////////////////////

import { createContext, useState, useEffect } from 'react';
import { useGetProductsMutation } from "../slices/productApiSlice";

export const ProductContext = createContext(null);

export const ProductProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [getProducts, { isLoadingGetProducts }] = useGetProductsMutation();

  const [products, setProducts] = useState([]);
const [loading, setLoading] = useState(true);

useEffect(() => {
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await getProducts().unwrap();
      //const data = await response.json();
      setProducts(response);
    } catch (error) {
      console.error('Failed to fetch products', error);
    } finally {
      setLoading(false);
    }
  };

  fetchProducts();
}, []);

return (
  <ProductContext.Provider value={{ products, loading }}>
    {children}
  </ProductContext.Provider>
);
}
