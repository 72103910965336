 import PaymentScreen from '../components/paymentForm1.jsx'; 
import "../styles/postPayment.css"; 


const Payment = () => {
    return (
  <div>

    <PaymentScreen  />
  </div>
  );
};

export default Payment;
