import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  promoCode: '',
  promoName: '',
  amount: '',
  isActive: '',
};

const promoSlice = createSlice({
  name: 'promo',
  initialState,
  reducers: {
    updatePromoCode: (state, action) => {
      state.promoCode = action.payload;
    },
    updatePromoName: (state, action) => {
        state.promoName = action.payload;
      },
    updateAmount: (state, action) => {
      state.amount = action.payload;
    },
    updateIsActive: (state, action) => {
      state.isActive = action.payload;
    },
    resetPromoCode: (state) => {
      state.promoCode = '';
      state.promoName = '';
      state.amount = '';
      state.isActive = '';
    }
  }
});

export const {  updatePromoCode, updatePromoName, updateAmount, updateIsActive, resetPromoCode } = promoSlice.actions;
export default promoSlice.reducer;